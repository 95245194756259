// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-academicadvantage-js": () => import("./../../../src/pages/academicadvantage.js" /* webpackChunkName: "component---src-pages-academicadvantage-js" */),
  "component---src-pages-alwaysapril-js": () => import("./../../../src/pages/alwaysapril.js" /* webpackChunkName: "component---src-pages-alwaysapril-js" */),
  "component---src-pages-search-results-js": () => import("./../../../src/pages/search-results.js" /* webpackChunkName: "component---src-pages-search-results-js" */),
  "component---src-pages-shareasmile-js": () => import("./../../../src/pages/shareasmile.js" /* webpackChunkName: "component---src-pages-shareasmile-js" */),
  "component---src-pages-shareasmile-terms-conditions-js": () => import("./../../../src/pages/shareasmile-terms-conditions.js" /* webpackChunkName: "component---src-pages-shareasmile-terms-conditions-js" */),
  "component---src-pages-w-4-w-application-js": () => import("./../../../src/pages/w4w-application.js" /* webpackChunkName: "component---src-pages-w-4-w-application-js" */),
  "component---src-pages-w-4-w-js": () => import("./../../../src/pages/w4w.js" /* webpackChunkName: "component---src-pages-w-4-w-js" */),
  "component---src-pages-w-4-w-step-1-js": () => import("./../../../src/pages/w4w-step-1.js" /* webpackChunkName: "component---src-pages-w-4-w-step-1-js" */),
  "component---src-pages-w-4-w-step-2-js": () => import("./../../../src/pages/w4w-step-2.js" /* webpackChunkName: "component---src-pages-w-4-w-step-2-js" */),
  "component---src-pages-wisdom-terms-conditions-js": () => import("./../../../src/pages/wisdom-terms-conditions.js" /* webpackChunkName: "component---src-pages-wisdom-terms-conditions-js" */),
  "component---src-templates-anesthesia-options-js": () => import("./../../../src/templates/anesthesia-options.js" /* webpackChunkName: "component---src-templates-anesthesia-options-js" */),
  "component---src-templates-contact-us-js": () => import("./../../../src/templates/contact-us.js" /* webpackChunkName: "component---src-templates-contact-us-js" */),
  "component---src-templates-dental-implants-today-js": () => import("./../../../src/templates/dental-implants-today.js" /* webpackChunkName: "component---src-templates-dental-implants-today-js" */),
  "component---src-templates-get-started-js": () => import("./../../../src/templates/get-started.js" /* webpackChunkName: "component---src-templates-get-started-js" */),
  "component---src-templates-index-js": () => import("./../../../src/templates/index.js" /* webpackChunkName: "component---src-templates-index-js" */),
  "component---src-templates-lifestyle-dental-implants-js": () => import("./../../../src/templates/lifestyle-dental-implants.js" /* webpackChunkName: "component---src-templates-lifestyle-dental-implants-js" */),
  "component---src-templates-lifestyle-experience-js": () => import("./../../../src/templates/lifestyle-experience.js" /* webpackChunkName: "component---src-templates-lifestyle-experience-js" */),
  "component---src-templates-lifestyle-js": () => import("./../../../src/templates/lifestyle.js" /* webpackChunkName: "component---src-templates-lifestyle-js" */),
  "component---src-templates-lifestyle-patient-js": () => import("./../../../src/templates/lifestyle-patient.js" /* webpackChunkName: "component---src-templates-lifestyle-patient-js" */),
  "component---src-templates-lifestyle-reviews-js": () => import("./../../../src/templates/lifestyle-reviews.js" /* webpackChunkName: "component---src-templates-lifestyle-reviews-js" */),
  "component---src-templates-open-for-care-js": () => import("./../../../src/templates/open-for-care.js" /* webpackChunkName: "component---src-templates-open-for-care-js" */),
  "component---src-templates-our-oral-surgery-practice-js": () => import("./../../../src/templates/our-oral-surgery-practice.js" /* webpackChunkName: "component---src-templates-our-oral-surgery-practice-js" */),
  "component---src-templates-patient-instructions-js": () => import("./../../../src/templates/patient-instructions.js" /* webpackChunkName: "component---src-templates-patient-instructions-js" */),
  "component---src-templates-patient-stories-js": () => import("./../../../src/templates/patient-stories.js" /* webpackChunkName: "component---src-templates-patient-stories-js" */),
  "component---src-templates-privacy-policy-js": () => import("./../../../src/templates/privacyPolicy.js" /* webpackChunkName: "component---src-templates-privacy-policy-js" */),
  "component---src-templates-procedures-js": () => import("./../../../src/templates/procedures.js" /* webpackChunkName: "component---src-templates-procedures-js" */),
  "component---src-templates-single-doctor-js": () => import("./../../../src/templates/singleDoctor.js" /* webpackChunkName: "component---src-templates-single-doctor-js" */),
  "component---src-templates-single-instruction-js": () => import("./../../../src/templates/singleInstruction.js" /* webpackChunkName: "component---src-templates-single-instruction-js" */),
  "component---src-templates-single-procedure-js": () => import("./../../../src/templates/singleProcedure.js" /* webpackChunkName: "component---src-templates-single-procedure-js" */),
  "component---src-templates-single-review-js": () => import("./../../../src/templates/singleReview.js" /* webpackChunkName: "component---src-templates-single-review-js" */),
  "component---src-templates-thank-you-js": () => import("./../../../src/templates/thank-you.js" /* webpackChunkName: "component---src-templates-thank-you-js" */),
  "component---src-templates-three-d-imaging-js": () => import("./../../../src/templates/three-d-imaging.js" /* webpackChunkName: "component---src-templates-three-d-imaging-js" */),
  "component---src-templates-your-first-visit-js": () => import("./../../../src/templates/your-first-visit.js" /* webpackChunkName: "component---src-templates-your-first-visit-js" */)
}

